import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { ChevronDown } from 'lucide-react';

const Footer = () => {
    const today = new Date();
    const [expandedSection, setExpandedSection] = useState(null);

    const toggleSection = (section) => {
        setExpandedSection(expandedSection === section ? null : section);
    };

    return (
        <footer className="quic-footer">
            <div className="quic-footer-content">
                <div className="quic-footer-main">
                    <h3>Quic</h3>
                    <span>Odd jobs. Small jobs. Quic jobs.</span>
                </div>
                <div className="quic-footer-links">
                    <div className="quic-footer-column">
                        <h6 onClick={() => toggleSection("Quick Links")} className="footer-section-header">
                            Quick Links
                            <ChevronDown
                                className={`chevron-icon ${expandedSection === "Quick Links" ? "rotated" : ""}`}
                            />
                        </h6>
                        <ul className="footer-links" style={{ display: expandedSection === "Quick Links" ? "block" : "none" }}>
                            <li><Link to="/add-service">Add a listing</Link></li>
                            <li><Link to="/how-it-works">How it works</Link></li>    
                            <li><Link to="/frequently-asked-questions">FAQs</Link></li>
                            <li><Link to="/browse">Browse available services</Link></li>
                            <li><Link to="/types-of-services">Types of services we accept</Link></li>
                            <li><Link to="/dashboard">Service dashboard</Link></li>
                        </ul>
                    </div>
                    <div className="quic-footer-column">
                        <h6 onClick={() => toggleSection("Company")} className="footer-section-header">
                            Company
                            <ChevronDown
                                className={`chevron-icon ${expandedSection === "Company" ? "rotated" : ""}`}
                            />
                        </h6>
                        <ul className="footer-links" style={{ display: expandedSection === "Company" ? "block" : "none" }}>
                            <li><Link to="/about-quic">About</Link></li>
                            <li><Link to="/feedback-form">Feedback</Link></li>
                            <li><a href="mailto:hello@quicjobs.co.uk">Get in touch</a></li>
                            <li><Link to="/quic-updates">Road map</Link></li>
                            <li><Link to="/terms-of-services">Terms of Services</Link></li>
                            <li><Link to="/privacy-policy">Privacy policy</Link></li>
                        </ul>
                    </div>
                    <div className="quic-footer-column">
                        <h6 onClick={() => toggleSection("Social")} className="footer-section-header">
                            Social
                            <ChevronDown
                                className={`chevron-icon ${expandedSection === "Social" ? "rotated" : ""}`}
                            />
                        </h6>
                        <ul className="footer-links" style={{ display: expandedSection === "Social" ? "block" : "none" }}>
                            <li><a href="https://www.facebook.com/people/Quic-jobs-UK/61556206433536/" target="_blank" rel="noopener noreferrer">Facebook</a></li>
                            <li><a href="https://www.instagram.com/quicjobs_uk" target="_blank" rel="noopener noreferrer">Instagram</a></li>  
                        </ul>
                    </div>
                </div>
            </div>
            <div className="quic-footer-bottom">
                <p>&copy; {today.getFullYear()} Quic Jobs Limited. All rights reserved.</p>
            </div>
        </footer>
    );
};

export default Footer;