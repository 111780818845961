import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import config from "../contexts/config";
import { generateProductSlug } from "../contexts/utils";
import {
  SprayCan,
  Wrench,
  NotebookPen,
  PawPrint,
  Flower2,
  Truck,
  Car,
  Router,
  Sparkles,
  Baby,
  HeartHandshake
} from "lucide-react";

const categoryIconMap = {
  "Cleaning": SprayCan,
  "Handyman": Wrench,
  "Tutoring": NotebookPen,
  "Pets": PawPrint,
  "Gardening": Flower2,
  "Moving": Truck,
  "Car Related": Car,
  "IT Set Up": Router,
  "Selfcare": Sparkles,
  "Baby Sitting": Baby,
  "Others": HeartHandshake
};

const categoryImageMap = {
  "Cleaning": "/images/default/d_cleaning.avif",
  "Handyman": "/images/default/d_handyman.avif",
  "Tutoring": "/images/default/d_tutor.avif",
  "Pets": "/images/default/d_pets.avif",
  "Gardening": "/images/default/d_gardening.avif",
  "Moving": "/images/default/d_moving.avif",
  "Car Related": "/images/default/d_car.avif",
  "IT Set Up": "/images/default/d_itsetup.avif",
  "Selfcare": "/images/default/d_selfcare.avif",
  "Baby Sitting": "/images/default/d_babysitting.avif",
  "Others": "/images/default/d_others.avif"
};

const categories = Object.keys(categoryIconMap);

const Home = () => {
  const navigate = useNavigate();
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [globalLoading, setGlobalLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const fetchLatestServices = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${config.API_URL}/services/public`);
        const sortedServices = response.data
          .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
          .slice(0, 4);
        setServices(sortedServices);
      } catch (error) {
        console.error("Error fetching services:", error);
        setError("An error occurred while fetching services.");
      } finally {
        setLoading(false);
      }
    };

    const initialize = async () => {
      await fetchLatestServices();
      setGlobalLoading(false); 
    };

    initialize();

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleHowItWorks = () => {
    navigate("/how-it-works");
  };

  const handleCategoryClick = (filter) => {
    navigate("/browse", { state: { selectedCategory: filter } });
  };

  const renderListings = () => {
    if (loading) 
      return <p>Loading listings...</p>;
    if (error) 
      return <p>{error}</p>;
    if (services.length === 0) 
      return <p>No services available.</p>;
    

    return (
      <div className={`latest-listings ${isMobile ? "column-layout" : "row-layout"}`}>
        {services.map((service) => {
          const Icon = categoryIconMap[service.service_category]; 
          return (
            <div key={service.id} className="product-card">
              <Link
                to={`/products/${generateProductSlug(service.service_name, service.id)}`}
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <div className="browse-service-image-container">
                  <img
                    src={service.service_photos1 || categoryImageMap[service.service_category] || "/images/listing_img.avif"}
                    alt={service.service_name}
                    className="service-image"
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = categoryImageMap[service.service_category] || "/images/listing_img.avif";
                    }}
                  />
                  {service.service_category && (
                    <div className="service-category-tag">
                      {Icon && <Icon size={16} className="category-icon-home" />} 
                      <span>{service.service_category}</span>
                    </div>
                  )}
                </div>
                <div className="browse-service-info">
                  <h3 className="browse-service-name">{service.service_name}</h3>
                  <p className="browse-service-price"> £{Math.round(service.price)}/h</p>
                </div>
                <div className="browse-service-details">
                  <div className="browse-user-info">
                    <div className="browse-profile-picture">
                      {service.profile_picture ? (
                        <img
                          src={service.profile_picture}
                          alt={service.username}
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = '';
                          }}
                        />
                      ) : (
                        <span className="profile-initials">
                          {service.username ? service.username[0].toUpperCase() : '?'}
                        </span>
                      )}
                    </div>
                    <span className="user-name">{service.username}</span>
                  </div>
                  <p className="service-area">
                    {service.service_area.replace(/, UK$/, '')}
                  </p>
                </div>
              </Link>
            </div>
          );
        })}
      </div>
    );
  };

  if (globalLoading) {
    return (
      <div className="global-spinner">
        <p>Loading...</p>
      </div>
    );
  }

  return (
    <>
      <main className="main-content-hero">
        <div className="container-hero">
          <section id="hero">
            <img src="/images/hp_hero.avif" alt="hero" width="1200" height="auto" />
            <h1>A local directory, where people in your area share their services</h1>
            <p>
              Your one-stop shop for finding freelancers in your area
            </p>
            <button onClick={handleHowItWorks}>How it works</button>
          </section>
        </div>

        <section className="services-carousel">
          <div className="header-row">
            <div className="latest-category-header">
              <h3>What do you need?</h3>
            </div>
            <div className="learn-more">
              <Link to="/types-of-services">About categories</Link>
            </div>
          </div>
          <div className="carousel-container">
          {categories.map((category) => {
            const Icon = categoryIconMap[category];
            return (
              <div key={category} className="carousel-item" onClick={() => handleCategoryClick(category)}>
                <Icon className="category-icon" size={20} />
                <span>{category}</span>
              </div>
            );
          })}
        </div>
        </section>

        <section className="latest-listings-container">
          <div className="latest-listings-header">
            <h3>Latest listings</h3>
          </div>
          {renderListings()}
          <div className="browse-more-link">
            <Link to="/browse">Browse more &gt;&gt;&gt;</Link>
          </div>
        </section>

        <section className="how-to-use">
          <h2>How to use Quic</h2>
          <div className="how-to-steps-container">
            <div className="how-to-step">
              <img src="/images/how-to-steps1.avif" alt="Step 1: Browse Services" />
              <h3>1. Browse available services</h3>
              <p>Browse and find available freelancers in your area that suits your needs.</p>
            </div>
            <div className="how-to-step">
              <img src="/images/how-to-steps2.avif" alt="Step 2: Chat with the Seller" />
              <h3>2. Get in touch</h3>
              <p>Speak directly with the seller through chat or mobile.</p>
            </div>
            <div className="how-to-step">
              <img src="/images/how-to-steps3n.avif" alt="Step 3: Send Booking Request" />
              <h3>3. Write a review</h3>
              <p>
                Job done? Share your experience by writing a review for the seller.
              </p>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Home;